
import { mapState } from "vuex";
import Auth from "./auth/Index.vue";

export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Auth,
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.category.list,
    }),
    menuList() {
      return [
        {
          name: "Altın & Gümüş",
          children: this.categoryList,
        },
        {
          name: "Serbest Piyasa",
          children: [
            {
              name: "Altın",
              slug: "altin",
            },
            {
              name: "Döviz",
              slug: "doviz",
            },
          ],
        },
        // {
        //   name: 'Forum',
        //   slug: '/forum',
        // },
      ];
    },
  },
};
