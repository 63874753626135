import { render, staticRenderFns } from "./noAds.vue?vue&type=template&id=5d194c1c&"
import script from "./noAds.vue?vue&type=script&lang=js&"
export * from "./noAds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/home/forge/altinmetre.com/components/AppHeader.vue').default,AppFooter: require('/home/forge/altinmetre.com/components/AppFooter.vue').default})
