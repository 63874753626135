/* eslint-disable */
export default function ({ $axios }, inject) {
  $axios.setBaseURL(process.env.BASE_URL);

  $axios.interceptors.request.use(
    async (config) => {
      if (!config.url.includes("auth")) {
        const tkn = generateToken("bemsoft", 155);
        config.headers.common["origin-token"] = tkn.token;
        config.headers.common["origin-server-id"] = tkn.startIndex;
        config.headers.common["origin-server-state"] = tkn.endIndex;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  function shuffleString(str) {
    const strArray = str.split("");
    for (let i = strArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [strArray[i], strArray[j]] = [strArray[j], strArray[i]];
    }
    return strArray.join("");
  }

  function generateToken(secretWord, length = 20) {
    const randomString = Array.from({ length }, () =>
      Math.random().toString(36).charAt(2)
    ).join("");
    const shuffledSecret = shuffleString(secretWord);
    const startIndex = Math.floor(
      Math.random() * (length - secretWord.length + 1)
    );
    const endIndex = startIndex + secretWord.length;
    const generatedToken =
      randomString.slice(0, startIndex) +
      shuffledSecret +
      randomString.slice(endIndex);
    return {
      token: generatedToken,
      startIndex: startIndex,
      endIndex: endIndex,
    };
  }

  const processErrors = (error, sender, onError) => {
    if (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 401:
            // router.push('/logout');
            break;

          case 403:
            // router.push('/access-denied');
            break;

          case 404:
            // router.push('/not-found');
            break;

          default:
            console.warn(`Bilinmeyen Hata - Hata Kodu: ${status}`);
            break;
        }
      } else {
        console.warn(error, `İÇ HATA: api => ${sender}`);
      }
    } else {
      console.warn("Bilinmeyen Hata");
    }

    if (onError) onError(error);
  };

  const get = async ({ path, params, onSuccess, onError, onFinish }) => {
    await $axios
      .get(path, { params })
      .then((response) => onSuccess(response))
      .catch((error) => processErrors(error, "get", onError))
      .then(() => onFinish());
  };

  const post = async ({ path, body, onSuccess, onError, onFinish }) => {
    await $axios
      .post(path, body)
      .then((response) => onSuccess(response))
      .catch((error) => processErrors(error, "post", onError))
      .then(() => onFinish());
  };

  const put = async ({ path, body, onSuccess, onError, onFinish }) => {
    await $axios
      .put(path, body)
      .then((response) => onSuccess(response))
      .catch((error) => processErrors(error, "put", onError))
      .then(() => onFinish());
  };

  const remove = async ({ path, onSuccess, onError, onFinish }) => {
    await $axios
      .delete(path)
      .then((response) => onSuccess(response))
      .catch((error) => processErrors(error, "remove", onError))
      .then(() => onFinish());
  };

  // Inject to context as $api
  inject("apiService", {
    get,
    post,
    put,
    remove,
  });
}
