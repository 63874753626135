
export default {
  name: 'Card',
  props: {
    cardClass: {
      type: String,
      default: null,
    },
    headerClass: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
    footerClass: {
      type: String,
      default: null,
    },
  },
};
