
/* eslint-disable no-nested-ternary */
import { mapState, mapMutations } from "vuex";

export default {
  name: "TopCurrencyList",
  fetchKey: "top-currency-list-mobile",
  data() {
    return {
      currencyCodes: [
        "BTCUSDT",
        "GA",
        "ETHUSDT",
        "USD",
        "EUR",
        "XAUUSD",
        "XAGUSD",
        "HH",
      ],
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.currency.list,
      isSocketAcitve: (state) => state.currency.socketActive,
    }),
    currencyList() {
      return this.list.filter((A) => this.currencyCodes.includes(A.code));
    },
  },
  async fetch() {
    if (!this.$store.state.currency.list.length) {
      await this.$store.dispatch("currency/getList");
    }
  },
  methods: {
    ...mapMutations({
      activateSocket: "currency/activateSocket",
    }),
    getIcon(currency) {
      if (currency.rateSymbol === "+") return ["fas", "caret-up"];
      if (currency.rateSymbol === "-") return ["fas", "caret-down"];
      return ["fas", "minus"];
    },
    getIconClass(currency) {
      return [
        "fa-sm",
        {
          "text-gray-400": !currency.priceUpdated,
          "text-teal-500": currency.rateSymbol === "+",
          "text-red-500": currency.rateSymbol === "-",
        },
      ];
    },
    getFraction(code) {
      return ["USD", "EUR"].includes(code) ? 4 : 2;
    },
    getDiff(currentPrice, newPrice) {
      return currentPrice > newPrice ? 1 : currentPrice < newPrice ? -1 : 0;
    },
    listenSocket() {
      this.$socket.currency(this.list, this.$store);
    },
    isPriceUp(currency) {
      return currency.priceUpdated && currency.diff > 0;
    },
    isPriceDown(currency) {
      return currency.priceUpdated && currency.diff < 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isSocketAcitve) {
        this.activateSocket();
        this.listenSocket();
      }
    });
  },
};
