/* eslint-disable */
const state = () => ({
  singleCategory: null,
  list: [],
});

const mutations = {
  setSingleCategory: (state, payload) => {
    state.singleCategory = null;
    if (payload) state.singleCategory = payload;
  },
  setProducts: (state, payload) => {
    state.singleCategory.products = payload.filter(
      (product) =>
        product?.sellers?.length &&
        product?.category?.id === state.singleCategory.id
    );
  },
  updateList: (state, payload) => {
    state.list = payload || [];
  },
};

const getters = {
  getSingleCategory: (state) => {
    return state.singleCategory;
  },
  getAllCategoriesProducts: (state, getters, rootState) => {
    return state.list.map((category) => ({
      ...category,
      products: rootState.product.list.filter((product) => product?.sellers?.length && product?.category?.id === category.id)
    }));
  }
};

const actions = {
  getCategory: async function ({ commit }, data) {
    await this.$apiService.get({
      path: 'category',
      params: { ...data.query },
      onSuccess: (result) => {
        commit('setSingleCategory', result.data.data);
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  getList: async function ({ commit }, data) {
    await this.$apiService.get({
      path: 'categories',
      onSuccess: (result) => {
        commit('updateList', result.data.categories || []);
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  getCategoryProducts: async function ({}, data) {
    await this.$apiService.get({
      path: 'category/products',
      params: {
        query: { ...data.query },
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
};

export { state, actions, mutations, getters };
