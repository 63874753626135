/* eslint-disable no-empty-pattern */
const basePath = 'products';

const state = () => ({
  list: [],
  singleProduct: null,
});

const mutations = {
  updateList: (state, payload) => {
    state.list = (payload || []).map((product) => ({
      ...product,
      sellers: product.sellers.sort((a, b) => {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);
        return priceA - priceB;
      }),
    }));
  },
  updatePrice(state, payload) {
    const { productId, sellerId, price } = payload;
    state.list.map((product) => {
      if (productId === product.id) {
        const sellerIndex = product.sellers.findIndex(
          (seller) => seller.id === sellerId,
        );
        if (sellerIndex !== -1) {
          product.sellers[sellerIndex].price = price;
        }
      }
      return {
        ...product,
        sellers: product.sellers.sort((a, b) => {
          const priceA = parseFloat(a.price);
          const priceB = parseFloat(b.price);
          return priceA - priceB;
        }),
      };
    });
  },
  setSingleProduct(state, payload) {
    state.singleProduct = null;
    if (payload) state.singleProduct = payload;
  },
  updateSingleProductPrice(state, payload) {
    const { sellerId, price, updatedAt } = payload;
    if(!state.singleProduct) return;
    const sellerIndex = state.singleProduct.sellers.findIndex(
      (seller) => seller.id === sellerId,
    );

    if (sellerIndex !== -1) {
      state.singleProduct.sellers[sellerIndex].price = price;
      state.singleProduct.sellers[sellerIndex].updatedAt = updatedAt;
    }

    state.singleProduct.sellers = state.singleProduct.sellers.sort((a, b) => {
      const priceA = parseFloat(a.price);
      const priceB = parseFloat(b.price);
      return priceA - priceB;
    });
  },
};

const getters = {
  categoryProducts: (state) => (categoryId) => state.list.filter((product) => product?.category?.id === categoryId),
  getSingleProduct: (state) => state.singleProduct,
};

const actions = {
  getList: async function({ commit }, data) {
    await this.$apiService.get({
      path: basePath,
      onSuccess: (result) => {
        commit('updateList', result.data.products || []);
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  findOneBySlug: async function({ commit }, data) {
    await this.$apiService.get({
      path: `${basePath}/${data.slug}`,
      onSuccess: (result) => {
        commit('setSingleProduct', result.data.data);
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  toggleLike: async function({}, data) {
    await this.$apiService.post({
      path: `${basePath}/${data.productId}/likes`,
      onSuccess: (result) => data?.onSuccess?.(result),
      onError: (error) => data?.onError?.(error),
      onFinish: () => data?.onFinish?.(),
    });
  },
  getLikesCount: async function({}, data) {
    await this.$apiService.get({
      path: `${basePath}/${data.productId}/likes/count`,
      params: { ...data.params },
      onSuccess: (result) => data?.onSuccess?.(result),
      onError: (error) => data?.onError?.(error),
      onFinish: () => data?.onFinish?.(),
    });
  },
};

export {
  state,
  actions,
  mutations,
  getters,
};
