import io from "socket.io-client";

const socket = io("https://socket.altinmetre.com", {
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionAttempts: 10, 
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});


export default socket
