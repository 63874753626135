export default function(date) {
  const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const mounthName = new Intl.DateTimeFormat('tr', { month: 'long' }).format(
    date,
  );
  return {
    daily: `${hour}:${minute}`,
    weekly: `${day} ${mounthName}`,
    hour,
    minute,
    day,
    mounthName,
  };
}
