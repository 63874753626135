import moment from 'moment-timezone'

export default function (inputDateStr) {
  const inputDate = new Date(inputDateStr);

  const dateString = new Date().toLocaleString("en-US", {
    timeZone: "Europe/Istanbul",
  });
  const istanbulTimezone = "Europe/Istanbul";
  const formattedDateString = dateString.replace(/,/, "");
  const currentDate = new Date(formattedDateString);
  currentDate.setTime(
    currentDate.getTime() + moment.tz(istanbulTimezone).utcOffset() * 60000
  );

  const timeDifferenceInMilliseconds = currentDate - inputDate;
  const timeDifferenceInSeconds = Math.floor(
    timeDifferenceInMilliseconds / 1000
  );
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);
  const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
  const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);

  const remainingHours = timeDifferenceInHours % 24;
  const remainingMinutes = timeDifferenceInMinutes % 60;

  let timeDifferenceText = "";
  if (timeDifferenceInDays > 0) {
    timeDifferenceText += `${timeDifferenceInDays} gün `;
  }
  if (remainingHours > 0) {
    timeDifferenceText += `${remainingHours} saat `;
  }
  if (remainingMinutes > 0) {
    timeDifferenceText += `${remainingMinutes} dk önce`;
  }

  if (timeDifferenceText === "") {
    timeDifferenceText = "Şimdi";
  }

  return timeDifferenceText;
}
