import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c4dce22 = () => interopDefault(import('../pages/altin/index.vue' /* webpackChunkName: "pages/altin/index" */))
const _0737d7d0 = () => interopDefault(import('../pages/doviz/index.vue' /* webpackChunkName: "pages/doviz/index" */))
const _e3ee2534 = () => interopDefault(import('../pages/giris-yap.vue' /* webpackChunkName: "pages/giris-yap" */))
const _94bb502c = () => interopDefault(import('../pages/hakkimizda.vue' /* webpackChunkName: "pages/hakkimizda" */))
const _42fe5c62 = () => interopDefault(import('../pages/iletisim/index.vue' /* webpackChunkName: "pages/iletisim/index" */))
const _fcf2660a = () => interopDefault(import('../pages/kayit-ol.vue' /* webpackChunkName: "pages/kayit-ol" */))
const _3ac916b5 = () => interopDefault(import('../pages/sifre-sifirla.vue' /* webpackChunkName: "pages/sifre-sifirla" */))
const _34489992 = () => interopDefault(import('../pages/sifremi-unuttum.vue' /* webpackChunkName: "pages/sifremi-unuttum" */))
const _7a972cda = () => interopDefault(import('../pages/altin/_slug.vue' /* webpackChunkName: "pages/altin/_slug" */))
const _3bf395c0 = () => interopDefault(import('../pages/ayarlar/_slug.vue' /* webpackChunkName: "pages/ayarlar/_slug" */))
const _05813688 = () => interopDefault(import('../pages/doviz/_slug.vue' /* webpackChunkName: "pages/doviz/_slug" */))
const _04c9f13e = () => interopDefault(import('../pages/profil/_username.vue' /* webpackChunkName: "pages/profil/_username" */))
const _f98436e4 = () => interopDefault(import('../pages/sozlesme/_slug.vue' /* webpackChunkName: "pages/sozlesme/_slug" */))
const _98925dba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _31a1a0b1 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _1905d6a1 = () => interopDefault(import('../pages/_category/_product.vue' /* webpackChunkName: "pages/_category/_product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/altin",
    component: _7c4dce22,
    name: "altin"
  }, {
    path: "/doviz",
    component: _0737d7d0,
    name: "doviz"
  }, {
    path: "/giris-yap",
    component: _e3ee2534,
    name: "giris-yap"
  }, {
    path: "/hakkimizda",
    component: _94bb502c,
    name: "hakkimizda"
  }, {
    path: "/iletisim",
    component: _42fe5c62,
    name: "iletisim"
  }, {
    path: "/kayit-ol",
    component: _fcf2660a,
    name: "kayit-ol"
  }, {
    path: "/sifre-sifirla",
    component: _3ac916b5,
    name: "sifre-sifirla"
  }, {
    path: "/sifremi-unuttum",
    component: _34489992,
    name: "sifremi-unuttum"
  }, {
    path: "/altin/:slug",
    component: _7a972cda,
    name: "altin-slug"
  }, {
    path: "/ayarlar/:slug?",
    component: _3bf395c0,
    name: "ayarlar-slug"
  }, {
    path: "/doviz/:slug",
    component: _05813688,
    name: "doviz-slug"
  }, {
    path: "/profil/:username?",
    component: _04c9f13e,
    name: "profil-username"
  }, {
    path: "/sozlesme/:slug?",
    component: _f98436e4,
    name: "sozlesme-slug"
  }, {
    path: "/",
    component: _98925dba,
    name: "index"
  }, {
    path: "/:category",
    component: _31a1a0b1,
    name: "category"
  }, {
    path: "/:category/:product",
    component: _1905d6a1,
    name: "category-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
