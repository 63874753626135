import socket from "../../client/socket";

const setting = {
  room: "updateProductPriceRoom",
  action: "updateProductPrice",
  delay: 2500,
};

export default function (store, id = null) {
  setTimeout(() => {
    socket.emit("join", `${setting.room}${id ? "_" + id : ""}`);
    socket.on(setting.action, (product) => {
      store.commit("product/updatePrice", {
        productId: product.product,
        sellerId: product.seller,
        price: product.price,
        updatedAt: product.updatedAt
      });
      store.commit("product/updateSingleProductPrice", {
        sellerId: product.seller,
        price: product.price,
        updatedAt: product.updatedAt
      })
    });
  }, setting.delay);
}
