/* eslint-disable no-plusplus */
import moment from 'moment-timezone';
import getFullDateObject from './getFullDateObject';

export default function(data, period, limit = 8) {
  const newData = data
    .map((element) => {
      const dateString = new Date(element.createdAt).toLocaleString('en-US', {
        timeZone: 'Europe/Istanbul',
      });
      const istanbulTimezone = 'Europe/Istanbul';
      const formattedDateString = dateString.replace(/,/, '');
      const createdAt = new Date(formattedDateString);
      createdAt.setTime(
        createdAt.getTime() - moment.tz(istanbulTimezone).utcOffset() * 60000,
      );
      return {
        dateElement: getFullDateObject(createdAt),
        ...element,
      };
    })
    .reduce((result, element) => {
      const key = element.dateElement[period];
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(element);
      return result;
    }, {});

  const array = Object.entries(newData);
  if (array.length < 2) return [];
  const selectedItems = [];

  switch (period) {
    case 'daily':
      selectedItems.push(array[0][1]);
      if (limit > 2) {
        const interval = Math.floor((array.length - 2) / (limit - 2));
        for (let i = 2; i < limit; i++) {
          const index = 1 + (i - 1) * interval;
          selectedItems.push(array[index][1]);
        }
      }
      selectedItems.push(array[array.length - 1][1]);
      break;

    default:
      if (limit > 2 && array.length >= limit) {
        for (let i = 0; i < limit; i++) {
          if (array[i].length > 1) {
            selectedItems.push(array[i][1]);
          }
        }
      }
      break;
  }

  return selectedItems.map((data) => ({
    ...data.sort((a, b) => {
      const priceA = parseFloat(a.price);
      const priceB = parseFloat(b.price);
      return priceA - priceB;
    }),
  }));
}
