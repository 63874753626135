/* eslint-disable */
import generateTitle from "./generateTitle";
import calculateDateDifference from "./calculateDateDifference";
import capitalizeFirstLetter from "./capitalizeFirstLetter"
import getFullDateObject from "./getFullDateObject"
import chartDataParse from './chartDataParse'
import validateEmail from './validateEmail'
import addUtmSource from './addUtmSource'

export default function ({ app }, inject) {
  const helpers = { generateTitle, calculateDateDifference, capitalizeFirstLetter, getFullDateObject, chartDataParse, validateEmail, addUtmSource };
  inject("helper", helpers);
}
