import Vue from 'vue';
import moment from 'moment';

const dateFormat = (date) => new Date(date).toLocaleDateString('tr-TR');
const forumDateFormat = (date) => {
  const now = moment();
  const days = now.diff(date, 'days');

  const startOfDay = now.clone().startOf('day');
  const endOfDay = now.clone().endOf('day');
  const isToday = moment(date).isBetween(startOfDay, endOfDay);

  let result;

  if (isToday) {
    result = 'Bugün';
  } else if (days === 1) {
    result = 'Dün';
  } else if (days > 1) {
    result = moment(date).format('DD.MM.YYYY');
  }

  return result;
};

export default () => {
  Vue.filter('dateFormat', dateFormat);
  Vue.filter('forumDateFormat', forumDateFormat);
};
