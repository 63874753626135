/* eslint-disable no-empty-pattern */
const basePath = '/contact';

const state = () => ({});

const mutations = {};

const actions = {
  sendContactForm: async function({}, data) {
    await this.$apiService.post({
      path: basePath,
      body: data.form,
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
};

export { state, actions, mutations };
