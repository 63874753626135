/* eslint-disable no-empty-pattern */
/* eslint-disable no-nested-ternary */
import currencyResource from "./resource/currencyResource";
import moment from 'moment-timezone';

const basePath = "currency";

const state = () => ({
  list: [],
  isFirst: true,
  socketActive: false,
});

const mutations = {
  activateSocket: (state) => {
    state.socketActive = true;
  },
  updateList: (state, payload) => {
    state.list = payload || [];
  },
  passiveEffect(state, index) {
    state.list[index].priceUpdated = false;
    state.list[index].diff = 0;
  },
  updateCurrencyPrice(state, { payload, index }) {
    state.isFirst = false;
    const currentPrice = state.list[index].price;
    const newPrice = payload.selling;
    const newBuyingPrice = payload.buying;

    const newRate = Math.abs((currentPrice - newPrice).toFixed(3));
    const currentRate = state.list[index].rate ?? 0;

    state.list[index].rate = newRate !== 0 ? newRate : currentRate;
    state.list[index].price = newPrice;
    state.list[index].buyingPrice = newBuyingPrice;

    const diff = newPrice > currentPrice ? 1 : newPrice < currentPrice ? -1 : 0;

    state.list[index].diff = diff;
    state.list[index].rateSymbol = diff === 1 ? "+" : diff === -1 ? "-" : "";
    state.list[index].priceUpdated = true;

    const dateString = new Date(payload.updatedAt).toLocaleString("en-US", {
      timeZone: "Europe/Istanbul",
    });
    const istanbulTimezone = "Europe/Istanbul";
    const formattedDateString = dateString.replace(/,/, "");
    const updatedAt = new Date(formattedDateString);
    updatedAt.setTime(
      updatedAt.getTime() - moment.tz(istanbulTimezone).utcOffset() * 60000
    );

    state.list[index].updatedAt = updatedAt;
  },
};

const getters = {};

const actions = {
  getList: async function ({ commit }, data) {
    await this.$apiService.get({
      path: basePath,
      onSuccess: (result) => {
        if (result?.data?.data) {
          result.data.data = currencyResource(result.data.data);
        }
        commit("updateList", result.data.data || []);
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
};

export { state, actions, mutations, getters };
