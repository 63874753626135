import socket from "../../client/socket";

const setting = {
  room: "updateCurrencyPriceRoom",
  action: "updateCurrencyPrice",
  delay: 1000,
};

export default function (list, store) {
  setTimeout(() => {
    socket.emit("join", setting.room);
    socket.on(setting.action, (payload) => {
      const index = list.findIndex((item) => item.id === payload.currency);
      if (index !== -1) {
        store.commit("currency/updateCurrencyPrice", { payload, index });
        setTimeout(() => store.commit("currency/passiveEffect", index), 1800);
      }
    });
  }, setting.delay);
}
