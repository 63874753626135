
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    getIcon() {
      return this.isOpen ? ['fas', 'times'] : ['fas', 'bars'];
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    isOpen: {
      handler: function(newValue) {
        this.$emit('toggle', newValue);

        switch (newValue) {
          case true:
            document.body.classList.add('overflow-y-hidden', 'lg:overflow-y-auto');
            break;

          default:
            document.body.classList.remove('overflow-y-hidden', 'lg:overflow-y-auto');
            break;
        }
      },
    },
  },
};
