import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { LocalScheme } from '~auth/runtime'
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/giris-yap",
    "logout": "/",
    "home": "/",
    "callback": "/giris-yap"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new LocalScheme($auth, {
  "token": {
    "property": "accessToken",
    "global": true,
    "required": true,
    "type": "Bearer",
    "maxAge": 0
  },
  "user": {
    "property": "user",
    "autoFetch": false
  },
  "endpoints": {
    "login": {
      "url": "/auth/login",
      "method": "post"
    },
    "user": {
      "url": "/auth/user",
      "method": "get"
    }
  },
  "name": "local"
}))

  // google
  $auth.registerStrategy('google', new Oauth2Scheme($auth, {
  "clientId": "101915269797-60v10t4ljlph43tlfaoh8vdi22svdm0f.apps.googleusercontent.com",
  "codeChallengeMethod": "",
  "responseType": "code",
  "endpoints": {
    "authorization": "https://accounts.google.com/o/oauth2/auth",
    "userInfo": "https://api.altinmetre.com/auth/user",
    "token": "https://api.altinmetre.com/auth/social-login/google"
  },
  "name": "google",
  "scope": [
    "openid",
    "profile",
    "email"
  ]
}))

  // facebook
  $auth.registerStrategy('facebook', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://facebook.com/v2.12/dialog/oauth",
    "userInfo": "https://graph.facebook.com/v6.0/me?fields=id,name,picture{url}"
  },
  "clientId": "270510678987042",
  "scope": [
    "public_profile",
    "email",
    "public_profile",
    "email"
  ],
  "name": "facebook"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
