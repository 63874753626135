import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faExternalLinkAlt as freeFasFaExternalLinkAlt,
  faUser as freeFasFaUser,
  faUserPlus as freeFasFaUserPlus,
  faUserShield as freeFasFaUserShield,
  faSignInAlt as freeFasFaSignInAlt,
  faInfoCircle as freeFasFaInfoCircle,
  faCheckCircle as freeFasFaCheckCircle,
  faExclamationCircle as freeFasFaExclamationCircle,
  faSpinner as freeFasFaSpinner,
  faCircleNotch as freeFasFaCircleNotch,
  faCog as freeFasFaCog,
  faLock as freeFasFaLock,
  faHome as freeFasFaHome,
  faChevronRight as freeFasFaChevronRight,
  faBars as freeFasFaBars,
  faTimes as freeFasFaTimes,
  faCaretUp as freeFasFaCaretUp,
  faCaretDown as freeFasFaCaretDown,
  faMinus as freeFasFaMinus,
  faDotCircle as freeFasFaDotCircle,
  faHeart as freeFasFaHeart,
  faFire as freeFasFaFire,
  faCommentDots as freeFasFaCommentDots,
  faStar as freeFasFaStar,
  faHistory as freeFasFaHistory
} from '@fortawesome/free-solid-svg-icons'

import {
  faEye as freeFarFaEye,
  faEyeSlash as freeFarFaEyeSlash,
  faClock as freeFarFaClock,
  faPaperPlane as freeFarFaPaperPlane,
  faHeart as freeFarFaHeart,
  faBell as freeFarFaBell,
  faComments as freeFarFaComments
} from '@fortawesome/free-regular-svg-icons'

import {
  faGoogle as freeFabFaGoogle,
  faFacebookF as freeFabFaFacebookF,
  faTwitterSquare as freeFabFaTwitterSquare,
  faFacebookSquare as freeFabFaFacebookSquare,
  faInstagramSquare as freeFabFaInstagramSquare
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaExternalLinkAlt,
  freeFasFaUser,
  freeFasFaUserPlus,
  freeFasFaUserShield,
  freeFasFaSignInAlt,
  freeFasFaInfoCircle,
  freeFasFaCheckCircle,
  freeFasFaExclamationCircle,
  freeFasFaSpinner,
  freeFasFaCircleNotch,
  freeFasFaCog,
  freeFasFaLock,
  freeFasFaHome,
  freeFasFaChevronRight,
  freeFasFaBars,
  freeFasFaTimes,
  freeFasFaCaretUp,
  freeFasFaCaretDown,
  freeFasFaMinus,
  freeFasFaDotCircle,
  freeFasFaHeart,
  freeFasFaFire,
  freeFasFaCommentDots,
  freeFasFaStar,
  freeFasFaHistory,
  freeFarFaEye,
  freeFarFaEyeSlash,
  freeFarFaClock,
  freeFarFaPaperPlane,
  freeFarFaHeart,
  freeFarFaBell,
  freeFarFaComments,
  freeFabFaGoogle,
  freeFabFaFacebookF,
  freeFabFaTwitterSquare,
  freeFabFaFacebookSquare,
  freeFabFaInstagramSquare
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
