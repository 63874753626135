
import Menu from './Menu.vue';
import MenuToggler from './MenuToggler.vue';

export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  components: {
    Menu,
    MenuToggler,
  },
  methods: {
    onRouteChange() {
      this.$refs.menuToggler.toggle();
    },
  },
};
