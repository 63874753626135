
const Guest = () => import('./Guest.vue');
const User = () => import('./User.vue');

export default {
  name: 'Auth',
  components: {
    Guest,
    User,
  },
};
