/* eslint-disable */
export default function ({ store, redirect, route }) {
  if (
    store.state.auth.loggedIn &&
    !store.state.auth.user.username &&
    route.name !== 'ayarlar-slug'
  ) {
    return redirect('/ayarlar/hesap');
  }
}
