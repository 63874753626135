import Vue from "vue";

const currencyFormat = (
  number,
  currencyCode = "TRY",
  maximumFractionDigits = 4,
  minimumFractionDigits = 0
) =>
  new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(number);

const currencyFormatNumber = (
  number,
  currencyCode = "TRY",
  maximumFractionDigits = 4,
  minimumFractionDigits = 0
) =>
  new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits,
    minimumFractionDigits,
  })
    .format(number)
    .replace(/₺/g, "");

export default () => {
  Vue.filter("currencyFormat", currencyFormat);
  Vue.filter("currencyFormatNumber", currencyFormatNumber);
};
