export default function(data) {
  return data.map((element) => ({
    id: element._id,
    code: element.code,
    rate: element.price?.rate,
    name: element.name,
    slug: element.slug,
    description: element.description,
    price: element.price?.selling || 0,
    rateSymbol:element.price?.rate > 0 ? "+" : (element.rate?.price == 0 ? '' : '-'),
    buyingPrice: element.price?.buying || 0,
    currency: element.currency,
    updatedAt: new Date(element.price?.updatedAt),
    diff: 1,
    priceUpdated: false,
  }));
}
