/* eslint-disable no-empty-pattern */
const basePath = "graphic";

const state = () => ({});

const mutations = {};

const getters = {};

const actions = {
  getGraphicData: async function ({}, data) {
    await this.$apiService.get({
      path: `${basePath}/${data.route}/${data.period}/${data.limit}`,
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
};

export { state, actions, mutations, getters };
