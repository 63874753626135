
export default {
  name: 'Button',
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: Array,
      default: null,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) this.$emit('click');
    },
  },
};
