/* eslint-disable no-empty-pattern */
const basePath = '/auth/register';

const state = () => ({});

const mutations = {};

const actions = {
  createAccount: async function({}, data) {
    await this.$apiService.post({
      path: basePath,
      body: data.form,
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  sendVerificationCode: async function({}, data) {
    await this.$apiService.post({
      path: `${basePath}/send-verification-code`,
      body: {
        channel: data.channel,
        address: data.email,
        code: data?.code || '',
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  checkUnique: async function({}, data) {
    await this.$apiService.post({
      path: `${basePath}/check/${data.type}`,
      body: {
        value: data.value,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
};

export { state, actions, mutations };
