/* eslint-disable no-empty-pattern */
const basePath = '/user';

const state = () => ({});

const mutations = {};

const actions = {
  findUser: async function({}, data) {
    await this.$apiService.post({
      path: `${basePath}/find/${data.type}`,
      body: {
        value: data.username,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  checkPassword: async function({}, data) {
    await this.$apiService.post({
      path: 'auth/check-password',
      body: {
        ...data.form,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  updateSettings: async function({}, data) {
    await this.$apiService.post({
      path: `auth/update-settings/${data.type}`,
      body: {
        ...data.form,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  verifyResetPasswordToken: async function({}, data) {
    const { token } = data.form;

    await this.$apiService.post({
      path: 'user/verify-reset-password-token',
      body: {
        token,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  sendResetPassword: async function({}, data) {
    const { email } = data.form;

    await this.$apiService.post({
      path: 'user/send-reset-password',
      body: {
        email,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  resetPassword: async function({}, data) {
    const { password, passwordConfirmation, token } = data.form;

    await this.$apiService.post({
      path: 'user/reset-password',
      body: {
        password,
        passwordConfirmation,
        token,
      },
      onSuccess: (result) => {
        if (data?.onSuccess) data.onSuccess(result);
      },
      onError: (error) => {
        if (data?.onError) data.onError(error);
      },
      onFinish: () => {
        if (data?.onFinish) data.onFinish();
      },
    });
  },
  getProductLikes: async function({}, data) {
    await this.$apiService.get({
      path: 'products/user-likes',
      onSuccess: (result) => data?.onSuccess?.(result),
      onError: (error) => data?.onError?.(error),
      onFinish: () => data?.onFinish?.(),
    });
  },
};

export { state, actions, mutations };
