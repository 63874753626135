/* eslint-disable */
export default function (url, extraParams = {}) {
  var urlObj = new URL(url);
  var queryParams = urlObj.searchParams;

  queryParams.append("utm_source", "altinmetre.com");

  if (Object.keys(extraParams).length) {
    Object.entries(extraParams).forEach(([key, val]) => {
      queryParams.append(key, val);
    });
  }

  return urlObj.toString();
}
