
const PageNotFoundSVG = () => import('../static/images/svg/404.svg');

export default {
  props: ['error'],
  layout: 'noAds',
  components: {
    PageNotFoundSVG,
  },
  computed: {
    getTitle() {
      switch (this.error.statusCode) {
        case 404:
          return 'Sayfa Bulunamadı';

        default:
          return 'Sunucu Hatası';
      }
    },
  },
  head() {
    return {
      title: this.getTitle,
    };
  },
};
